import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { IoMdCheckboxOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { driverLists, deleteDrivers } from "../redux/action/driverAction";
import { toast } from "react-toastify";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from "axios"
import config from "../config/config"
import Modal from "react-bootstrap/Modal";
import { IoCloseSharp } from "react-icons/io5";

const initialState = {
    route_detail: "",
    route_preview: "",
    route_preview_name: "",
};

const DriverAdded = ({ onClose, bookingId, schoolId }) => {
    const [driverId, setDriverId] = useState("")
    const [iState, updateState] = useState(initialState)
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const { route_detail, route_preview, route_preview_name } = iState
    const handleModalClose = () => {
        onClose();
    };
    const dispatch = useDispatch();
    const driverList = useSelector((state) => state.driver?.driverList?.data);

    useEffect(() => {
        let obj = {
            shift_type: "",
            duty_status: "Available",
            search: "",
            page: 1,
        };
        dispatch(driverLists(obj));
    }, [])

    const routeFileHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (
                file.type === 'application/vnd.ms-excel' ||
                file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                const preview = URL.createObjectURL(file)
                updateState({
                    route_detail: file,
                    route_preview: preview,
                    route_preview_name: file.name
                });
            } else {
                toast.error("Only formats are allowed: xls, xlsx files", {
                    position: toast?.POSITION?.TOP_RIGHT,
                });
            }
        }
    }

    const clearRoute = () => {
        updateState({
            route_detail: "",
            route_preview: "",
            route_preview_name: ""
        });
    }

    const handleSubmitBtn = () => {
        const formData = new FormData()
        setLoader(true)
        formData.append("driver_id", driverId)
        formData.append("booking_id", bookingId)
        formData.append("file", route_detail)
        formData.append("school_id", schoolId)
        axios
            .post(`${config.Url}vendor/assign-driver`, formData, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                const data = response.data;
                if (response.status && response.status == 200) {
                    toast.success(data.message)
                    setShow(false)
                    clearRoute()
                    setLoader(false)

                } else {
                    toast.error(data.message)
                    setLoader(false)
                }
            })
            .catch((err) => {
                toast.error(err.response.data?.messsage)
                setLoader(false)
            })
    }
    return (
        <>
            <Button onClick={() => setShow(true)}>
                Assign
            </Button>
            <Modal show={show}>
                <Button className="closePopup" onClick={() => setShow(false)}>
                    <IoCloseSharp />
                </Button>
                <div className='popupBox p-3 p-md-5'>
                    <div>
                        <ul className="form d-flex flex-wrap p-0 pt-2">

                            <li className="col-12">
                                <label className="filedHd">Select driver for assign</label>
                                <select
                                    className="formSelect"
                                    name="year"
                                    value={driverId}
                                    onChange={(e) => setDriverId(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {driverList?.length > 0
                                        ? driverList?.map((item, i) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))
                                        : ""}
                                </select>
                                {/* <span style={{ color: "red" }}>
                                {errors && errors?.yearEmpty}
                            </span> */}
                            </li>

                            <li className="col-12">
                                <label className="filedHd">Student details (xlsx, xls)</label>
                                {route_detail &&
                                    <span
                                        className="m-2"
                                        onClick={clearRoute}
                                    >
                                        <RiDeleteBin5Line />
                                    </span>}
                                {!route_detail &&
                                    <>
                                        <div className="uplaodBox d-flex align-items-center">
                                            <Button className="btn">
                                                Upload
                                                <input
                                                    type="file"
                                                    className="file"
                                                    onChange={routeFileHandler}
                                                    onClick={(e) => (e.target.value = null)}
                                                    accept=".xls, .xlsx"
                                                />
                                            </Button>
                                        </div>
                                        {/* <span style={{ color: "red" }}>
                                        {errors && errors?.route_detailEmpty}
                                    </span> */}
                                    </>
                                }
                                {/* <DocumentPreview preview={route_preview} /> */}
                                {route_preview && <div className="uplaodBox d-flex align-items-center">
                                    {route_preview_name}
                                </div>}
                            </li>
                        </ul>
                        <div className='text-center mt-5'>
                            <Button className='smallBtn cancel me-3' onClick={handleSubmitBtn}>{loader ? <div class="spinner-border" role="status">
                                <span class="sr-only p-0"></span>
                            </div> : "Assign"}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default DriverAdded;